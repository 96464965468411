<template>
  <div>
    <SidebarLeft :class="{'sidebar-left-show': $store.state.sidebars.leftSidebar}">
      <FilterDirectoryForm v-if="isLeftSidebarVisible" ref="filter" @selected-name="selectedNameDirectory" />
    </SidebarLeft>

    <TableContainer
      v-if="directory"
      ref="table-container"
      :columns="tableData"
      :counter-filter="searchCount"
      :is-archive="false"
      :is-archiving="false"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-loading-new-item="$store.state.other.isLoadingNewItem"
      :rows="directories"
      :show-add-button="$can('edit', `${directory.accessName}-create`)"
      :sorting-fields="sortingFields"
      :title="directory.title"
      name="directory"
      @show-right-sidebar="showRightSidebar"
      @on-selected="selectItem"
      @delete-item="deleteItem"
      @update-data="updateData"
      :is-filter="false"
    >
      <template slot="head-buttons">
        <zem-link
          v-if="!$store.state.sidebars.leftSidebar"
          class="zem-link-mobile tw-gap-x-1"
          @click="() => {
            $store.commit('sidebars/changeLeftSidebar', true)
            isLeftSidebarVisible = true
          }"
        >
          <database-icon class="primary-icon" size="14"></database-icon>
          <span class="mobile-d-none">Список</span>
        </zem-link>
        <zem-link
          v-if="selectedDirectories.length > 0 && selectedDirectories.length < 2"
          class="zem-link-mobile tw-gap-x-1"
          @click="deleteItem"
        >
          <img :src="require('@/assets/icons/basket.svg')" alt="" class="tw-size-[18px] mobile-d-none" />
          <img :src="require('@/assets/icons/basket-white.svg')" alt="" class="tw-size-[18px] mobile-d" />
          <span class="mobile-d-none">{{ $t('Delete') }}</span>
        </zem-link>
      </template>
    </TableContainer>

    <SidebarRight :class="{'sidebar-right--show': $store.state.sidebars.rightSidebar}">
      <DirectoryDetails
        :current-item="currentItem"
        :includes-array="includesArray"
        :is-edit="$can('edit', `${directory.accessName}-update`)"
        :new-directory="newDirectory"
        :title-directory="directory.title"
        @update-data="updateData"
        @on-new-close="newDirectory = false"
      />
    </SidebarRight>
  </div>
</template>

<script>
import SidebarRight from '../components/SidebarRight'
import FilterDirectoryForm from '@/components/FilterDirectoryForm'
import TableContainer from '@/components/TableContainer'
import SidebarLeft from '@/components/SidebarLeft'
import DirectoryDetails from '@/components/DirectoryDetails'
import {eventBus} from '@/main'
import DirectoryService from '@/services/directory.service'
import ZemLink from '@/components/ui/ZemLink.vue'
import {directories} from '@/database/directoriesList'
import {DatabaseIcon} from "vue-feather-icons";

export default {
  components: {
    ZemLink,
    TableContainer,
    FilterDirectoryForm,
    DirectoryDetails,
    SidebarLeft,
    SidebarRight,
    DatabaseIcon,
  },
  data() {
    return {
      isLeftSidebarVisible: true,
      newDirectory: false,
      currentItem: {},
      directory: '',
      sortingFields: [],
      tableData: [],
      includesArray: [
        'number',
        'real_id',
        'real_brand_id',
        'updated_at',
        'created_at',
        'deleted_at',
        'brand_id',
        'country_id',
        'real_country_id',
        'region_id',
        'real_region_id',
        'unit_id',
        'real_unit_id',
        'id',
        'alias',
        'object',
        'is_explode',
        'is_base',
        'params',
        'pipeline_id',
        'total_orders',
        'real_pipeline_id',
        'params_schema',
        'slug',
      ],
      templates: {},
    }
  },

  computed: {
    searchCount() {
      return this.$store.state.directory.searchCount
    },
    directories() {
      return this.$store.state.directory.directoryItems
    },
    selectedDirectories() {
      return this.$store.state.directory.selectedItemsForDelete
    },
  },

  async mounted() {
    this.$store.commit('directory/changeSelectedDirectory', {})
    const {type, id} = this.$route.params
    if (type)
      this.selectedNameDirectory(
        directories.find(el => el.accessName === type),
        false
      )
    if (id) this.$store.commit('directory/itemsForDelete', [id])
    this.$store.commit('sidebars/changeLeftSidebar', true)
    this.$store.commit('sidebars/changeRightSidebar', false)
  },

  methods: {
    async updateData() {
      const {path, includes} = this.$store.state.directory.selectedDirectory
      await DirectoryService.getDirectory(path, includes).then(r => {
        const {id} = this.$route.params
        if (id) {
          const data = r.data.data.find(el => el.id === id)
          if (data)
            this.selectItem({
              column: {
                field: data.object,
              },
              row: data,
            })
          this.$router.push({name: 'directory-type', params: {type: this.directory.accessName}})
        }
        this.$store.commit('directory/getAllDirectoryItems', r.data)
        this.$store.commit('other/onChangeLoadingItems', false)
        eventBus.$emit('update-table-settings', [])
      })
    },
    selectedNameDirectory(data, onClickDirectories = true) {
      this.$store.commit('directory/changePage', 1)
      this.$store.commit('directory/changeSelectedDirectory', data)
      this.$store.commit('directory/itemsForDelete', [])
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.directory = data
      this.sortingFields = data.sortingFields
      this.tableData = data.fields
      this.newDirectory = false
      if (onClickDirectories) this.$router.push({name: 'directory-type', params: {type: data.accessName}})
      this.updateData()
      if (this.$store.getters['other/mobileContent']) this.$store.commit('sidebars/changeLeftSidebar', false)
    },
    selectItem({column, row}) {
      if (column.field !== 'checkbox') this.$store.commit('sidebars/changeRightSidebar', true)
      let updateData = Object.assign({}, row)

      if (updateData.hasOwnProperty('brand')) updateData['brand'] = row.brand
      if (updateData.hasOwnProperty('region'))
        updateData['region'] = {
          title: row.region.data.name,
          value: row.region.data.id,
        }
      if (updateData.hasOwnProperty('country'))
        updateData['country'] = {
          title: row.country.data.name,
          value: row.country.data.id,
        }

      this.currentItem = updateData

      this.newDirectory = false
      eventBus.$emit('new-edit-state-directory', false)

      const {id} = this.$route.params
      if (id !== row.id)
        this.$router.push({
          name: 'directory-id',
          params: {
            type: this.directory.accessName,
            id: row.id,
          },
        })
    },
    showRightSidebar() {
      this.$store.commit('sidebars/changeRightSidebar', true)
      this.$store.commit('directory/itemsForDelete', [])
      this.newDirectory = true
      this.currentItem = {}
      this.directory.fields.forEach(el => {
        if (el.hasOwnProperty('type')) {
          if (el.type === 'list-objects') this.currentItem[el.field] = {data: []}
          if (el.type === 'boolean') this.currentItem[el.field] = false
          if (el.type === 'number') this.currentItem[el.field] = 0
        } else {
          this.currentItem[el.field] = ''
        }
      })
    },
    async deleteItem() {
      this.$refs['table-container'].loadingDelete = true

      if (this.directory.multiDelete) {
        await DirectoryService.deleteDirectories(
          this.directory.removePath ? this.directory.removePath : this.directory.path
        )
      } else {
        await DirectoryService.deleteDirectory(
          this.directory.removePath ? this.directory.removePath : this.directory.path
        )
      }

      this.$toast.success('Элемент списка удален')
      this.$refs['table-container'].loadingDelete = false
      this.$refs['table-container'].showDeleteModal = false
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$store.commit('directory/itemsForDelete', [])
      eventBus.$emit('new-state', false)
      await this.updateData()
    },
  },
  beforeDestroy() {
    this.$store.commit('directory/getAllDirectoryItems', {
      data: [],
      meta: {
        custom: {
          total: null,
        },
        pagination: {
          current_page: null,
          total_pages: null,
          total: null,
        },
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
  &__container {
    height: 100vh;
  }
}
</style>
