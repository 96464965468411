<template>
  <div class="filter-directory">
    <div class="filter-directory__label">
      <span>{{ $t('Directory') }}</span>
      <div
        v-if="$store.getters['other/mobileContent']"
        @click="() => $store.commit('sidebars/changeLeftSidebar', false)"
      >
        <font-ai class="text-primary" icon="fa-solid fa-xmark"/>
      </div>
    </div>
    <p
      v-for="(directory, index) in directories()"
      :key="index"
      :class="{ 'active': selectedDirectory.accessName === directory.accessName }"
      class="text-normal list-item"
      @click="$emit('selected-name', directory)"
    >
      {{ directory.title }}
    </p>
  </div>
</template>

<script>
import {directories} from '@/database/directoriesList'

export default {
  computed: {
    selectedDirectory() {
      return this.$store.state.directory.selectedDirectory
    }
  },
  methods: {
    directories() {
      return directories.filter(el => this.$can('edit', `${el.accessName}-read`))
    },
  },
}
</script>

<style lang="scss">
.filter-directory {
  display: block;
  padding: 32px 24px;
  min-width: 192px;
  overflow: auto;
  height: 100%;

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: $color-mine-shaft;
  }

  .list-item {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    &:not(:first-child) {
      margin-top: 10px;
    }

    &.active {
      font-weight: 600;
      color: $color-accent;
    }
  }
}

@media screen and (max-width: 1440px) {
  .filter-directory {
    padding: 20px;
    min-width: 152px;
  }
}
</style>
