<template>
  <div class="color-picker tw-relative">
    <div :style="{backgroundColor: value}" class="tw-absolute tw-top-0 tw-left-0" @click="$refs.input.click()"></div>
    <input
      ref="input"
      :style="{backgroundColor: value}"
      :value="value"
      class="tw-relative tw-opacity-0"
      type="color"
      @input="updateColor($event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'ColorPicker',
  props: {
    value: {
      type: String,
      default: '#000000',
    },
  },
  methods: {
    updateColor(newValue) {
      this.$emit('input', newValue)
    },
  },
}
</script>

<style scoped>
.color-picker {
  width: 26px;
  height: 26px;

  div {
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  input {
    cursor: pointer;
  }
}
</style>
